import { autocomplete } from "@algolia/autocomplete-js";
import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";
import algoliasearch from "algoliasearch";
import React, {
  createElement,
  Fragment,
  useEffect,
  useMemo,
  useRef
} from "react";
import { render } from "react-dom";
import { useHistory } from "react-router-dom";

import {
  ALGOLIA_API_KEY,
  ALGOLIA_APPLICATION_ID,
  ALGOLIA_QUERY_SUGGESTIONS_INDEX
} from "../../../../appConfig";

export default function Autocomplete(props: any) {
  const containerRef = useRef(null);
  const history = useHistory();
  const urlParams = new URLSearchParams();

  const searchClient = useMemo(
    () => algoliasearch(ALGOLIA_APPLICATION_ID, ALGOLIA_API_KEY),
    []
  );

  const querySuggestionsPlugin = createQuerySuggestionsPlugin({
    searchClient,
    indexName: ALGOLIA_QUERY_SUGGESTIONS_INDEX,
    transformSource({ source }) {
      function createUrl(item: any) {
        urlParams.set("query", item.query);
        return `/search?${urlParams.toString()}`;
      }

      return {
        ...source,
        getItemUrl({ item }) {
          return createUrl(item);
        },
        templates: {
          item(params) {
            const { item } = params;

            return (
              <a
                className="aa-ItemLink"
                onClick={() => history.push(createUrl(item))}
              >
                {/* @ts-ignore */}
                {source.templates.item(params).props.children}
              </a>
            );
          }
        }
      };
    }
  });

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete({
      openOnFocus: true,
      container: containerRef.current,
      renderer: { createElement, Fragment, render },
      plugins: [querySuggestionsPlugin],
      placeholder: "Search Data Link",
      onSubmit: ({ state }) => history.push(`/search?query=${state.query}`),
      initialState: {
        query:
          new URL(window.location.toString()).searchParams.get("query") ?? ""
      },
      ...props
    });

    return () => {
      search.destroy();
    };
  }, [props]);

  return <div ref={containerRef} />;
}
