import React, { createRef } from "react";
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import { SliderButton } from "@typeform/embed-react";

import { IButtonActionFields } from "../../../../../contentful/__generated__/types";
import {
  MODAL_TYPES,
  useGlobalModalContext
} from "../../../../context/modal-context";
import ADPLeadForm from "../../../modals/adp-lead-form";
import ADPSignupModal from "../../../modals/adp-signup-modal";
import useTrackEvent from "../../../../analytics/hooks/useTrackEvent";
import "./style.scss";
import MarketoForm from "../../../contact-sales-forms";

const THEMES = [
  { name: "Orange", class: "action_button__orange_white" },
  { name: "Blue", class: "action_button__blue_white" },
  { name: "White-border", class: "action_button__white-border" },
  { name: "Transparent", class: "action_button__transparent" },
  { name: "Yellow", class: "action_button__yellow" }
];

type Props = {
  fields: IButtonActionFields;
};

const Action: React.FC<Props> = ({ fields }) => {
  const themeClass = THEMES.find(t => t.name === fields.theme)?.class;
  const className = `action_button ${themeClass}`;
  const sliderRef = createRef();
  const trackEvent = useTrackEvent();

  const openAction = (action: string) => {
    switch (action) {
      case "Open Create Account Modal":
        return () => openCreateAccount();
      case "Open Contact Sales Modal":
        return () => openADPLeadForm();
      case "Open Request Demo Modal":
        return () => openRequestDemoForm();
      default:
        return () => {};
    }
  };

  const { showModal } = useGlobalModalContext();
  const openCreateAccount = () => {
    showModal(MODAL_TYPES.ADP_SIGNUP_FORM, {
      title: "Create instance form",
      children: <ADPSignupModal />
    });
  };

  const openADPLeadForm = () => {
    showModal(MODAL_TYPES.ADP_LEAD_FORM, {
      title: "Create instance form",
      children: <ADPLeadForm />
    });
  };

  const openRequestDemoForm = () => {
    showModal(MODAL_TYPES.CONTACT_MODAL, {
      title: "Contact Sales",
      formElement: <MarketoForm formId="3102" />
    });
  };

  if (fields.action === "Open Monetize Data Survey Modal") {
    return (
      <div
        className="contentful-button__action"
        onClick={() => trackEvent("Supply Survey Initiated", {})}
      >
        <SliderButton
          id="kH5FQV"
          ref={sliderRef}
          className={`${className} typeform-button`}
          onSubmit={() =>
            trackEvent("Supply Survey Completed", {
              referralUrl: window.location.href,
              referrer: window.document.referrer,
              submission_origin: "supply-lead-survey@"
            })
          }
        >
          {fields.label}
        </SliderButton>
      </div>
    );
  }

  return (
    <div className="contentful-button__action">
      <div
        onClick={openAction(fields.action)}
        className={`${className} action-button`}
      >
        {fields.label}
      </div>
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { Action as ActionButton };
